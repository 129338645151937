<template>
  <div>
    <b-row class="content-header" v-if="this.customGmbValue">
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `light-primary`"
              />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="content-header" v-if="!this.customGmbValue">
      <b-col class="content-header-left mb-2" cols="12" md="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="10">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Google My Business
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div>
      <b-card block>
        <b-row>
          <b-col cols="3" v-if="!this.customGmbValue">
            <p class="app-title xs">Select a Client</p>
            <b-form-select v-model="selected" :options="options">
              <option
                v-for="option in options"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-form-select></b-col
          >
          <b-col cols="5">
            <p class="app-title xs">Showing GMB Data for Last 7 days</p>
            <div class="date-range-picker-container">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange"
                @update="updateValues"
                :dateFormat="dateFormat"
                control-container-class="picker-controller"
                :ranges="dateRanges"
                :auto-apply="false"
                :readonly="false"
              >
                <template v-slot:input="picker">
                  <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    class="mr-1"
                  ></feather-icon>
                  {{ formatDateDisplay(picker.startDate) }} -
                  {{ formatDateDisplay(picker.endDate) }}
                </template>
              </date-range-picker>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div>
      <b-card>
        <div class="schedule-hearder">
          <b-card-title>
            <h3 class="app-title mdsm">Google My Business</h3>
          </b-card-title>
          <input
            type="text"
            v-model="searchTerm"
            placeholder="Search by columns name."
          />
        </div>
        <vue-good-table
          class="custom-data-table"
          :rows="tableData"
          :columns="gmbTable"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BLink,
  BCardBody,
  BFormSelect,
  BCardTitle,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
// import 'vue-good-table/dist/vue-good-table.css';
export default {
  name: "gmb",
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    vSelect,
    BCardBody,
    DateRangePicker,
    BFormSelect,
    VueGoodTable,
    BCardTitle,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BAvatar,
  },
  props: {
    customGmbValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: "",
      pageLength: 5,
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      tableData: [
        // { name: 'John Doe', age: 25, email: 'john@example.com' },
        // { name: 'Jane Smith', age: 32, email: 'jane@example.com' },

        // Add more dummy data objects here
        {
          storeCode: "store code 1",
          name: "Business name 1",
          address: "Address 1",
          labels: "Labels 1",
          overallRating: "Overall rating 1",
          totalSearches: "12",
          directSearches: "13",
          discoverySearches: "18",
          totalViews: "1025",
          searchViews: "867",
          mapViews: "20",
          totalActions: "23",
          websiteActions: "43",
          directionActions: "4",
          callActions: "8",
        },
        {
          storeCode: "store code 2",
          name: "Business name 2",
          address: "Address 2",
          labels: "Labels 2",
          overallRating: "Overall rating 2",
          totalSearches: "19",
          directSearches: "13",
          discoverySearches: "18",
          totalViews: "1025",
          searchViews: "867",
          mapViews: "20",
          totalActions: "23",
          websiteActions: "43",
          directionActions: "4",
          callActions: "65",
        },
        {
          storeCode: "store code 3",
          name: "Business name 3",
          address: "Address 3",
          labels: "Labels 3",
          overallRating: "Overall rating 3",
          totalSearches: "21",
          directSearches: "13",
          discoverySearches: "18",
          totalViews: "1025",
          searchViews: "867",
          mapViews: "20",
          totalActions: "23",
          websiteActions: "43",
          directionActions: "4",
          callActions: "90",
        },
        {
          storeCode: "store code 4",
          name: "Business name 4",
          address: "Address 4",
          labels: "Labels 4",
          overallRating: "Overall rating 4",
          totalSearches: "21",
          directSearches: "14",
          discoverySearches: "18",
          totalViews: "1025",
          searchViews: "867",
          mapViews: "20",
          totalActions: "24",
          websiteActions: "44",
          directionActions: "4",
          callActions: "90",
        },
        {
          storeCode: "store code 5",
          name: "Business name 5",
          address: "Address 5",
          labels: "Labels 5",
          overallRating: "Overall rating 5",
          totalSearches: "21",
          directSearches: "15",
          discoverySearches: "18",
          totalViews: "1025",
          searchViews: "867",
          mapViews: "20",
          totalActions: "25",
          websiteActions: "45",
          directionActions: "4",
          callActions: "90",
        },
      ],
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      clients: [],
      selected: null,
      options: [
        { value: null, text: "Select a Client" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      opens: true,
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    //TODO
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: `/client/${response.data.client.slug}`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Google My Business",
            to: `/client/${response.data.client.slug}/dashboard/google-ads`,
            active: true,
          });

          // if (
          //   response.data.client &&
          //   response.data.client.integrations.googleAds &&
          //   response.data.client.googleAdsCustomerID
          // ) {
          //   this.getReportData();
          // }
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    fetchClients() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.clients;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("DD-MM-YYYY");
      let endDate = moment(values.endDate).format("DD-MM-YYYY");
      this.$emit("fetchNewData", startDate, endDate);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );

      if (!classes.disabled) {
        classes.disabled = d1 === d2;
      }
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      };

      return ranges;
    },
    gmbTable() {
      let tableColumns = [
        {
          label: "Store Code",
          field: "storeCode",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Address",
          field: "address",
        },
        {
          label: "Labels",
          field: "labels",
        },
        {
          label: "Overall Rating",
          field: "overallRating",
        },
        {
          label: "Total Searches",
          field: "totalSearches",
        },
        {
          label: "Direct Searches",
          field: "directSearches",
        },
        {
          label: "Discovery Searches",
          field: "discoverySearches",
        },
        {
          label: "Total Views",
          field: "totalViews",
        },
        {
          label: "Search Views",
          field: "searchViews",
        },
        {
          label: "Map Views",
          field: "mapViews",
        },
        {
          label: "Total Actions",
          field: "totalActions",
        },
        {
          label: "Website Actions",
          field: "websiteActions",
        },
        {
          label: "Direction Actions",
          field: "directionActions",
        },
        {
          label: "Call Actions",
          field: "callActions",
        },
      ];
      return tableColumns;
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}
.date-range {
  font-size: 11px !important;
}
.picker-controller {
  background-color: #f5f6fa;
}
.date-range-picker-container {
  background-color: #f5f6fa;
  padding: 8px;
  width: 240px;
}
.main-gmb-container {
  padding: 0 13%;
}
.schedule-hearder {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
</style>
